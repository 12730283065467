import React from "react";
// Component
// import Resume from "./Resume";

// Style
import "../Styles/Navbar.css";

const Navbar = () => {
  return (
    <div className="container">
      <div className="sub-container menu">{/* <Resume /> */}</div>
    </div>
  );
};

export default Navbar;
