import React from "react";
// Media
import portafolioLogo from "../assets/portafolio-logo.svg";

const HomePage = () => {
  return (
    <div className="home-banner home-screen">
      <div className="home-content">
        <img alt="Taiba althunayan logo" src={portafolioLogo} />
        <p>
          HEY THERE! I'M <span className="taiba-sparkle">TAIBA</span> A SOFTWARE
          DEVELOPER AND A UI/UX DESIGNER.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
