import React from "react";
// Style
import "../Styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer-banner home-screen">
      <div className="footer-icons">
        <a href="https://www.instagram.com/talthunayan_">
          Intagram
          {/* <img alt="Intagram" className="nav-logo" src={instagram} /> */}
        </a>
        <a href="https://www.twitter.com/_Talthunayan">
          Twitter
          {/* <img alt="Twitter" className="nav-logo" src={twitter} /> */}
        </a>
        <a href="https://www.linkedin.com/in/talthunayan98">
          Linkedin
          {/* <img alt="Linkedin" className="nav-logo" src={linkedin} /> */}
        </a>
      </div>
    </div>
  );
};

export default Footer;
