import React from "react";
// Components
import Card from "./Card";

// Data
import { CARDS } from "../data/card";

// Style
import "../Styles/CardSection.css";

const cards = CARDS.map((card) => {
  return <Card card={card} />;
});

const CardSection = () => {
  return (
    <div className="card-container home-screen">
      <p className="project-title">Projects</p>
      <div className="card-section-container">{cards}</div>
    </div>
  );
};

export default CardSection;
