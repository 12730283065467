import React from "react";
// Media
// import portafolioLogo from "../assets/portafolio-logo.svg";

const MaxScreen = () => {
  return (
    <div className="home-banner max-screen">
      <div className="max-screen-content">
        <p className="max-screen-paragraph">
          The Screen size is not supported by the website kindly minimize the
          screen
        </p>
      </div>
    </div>
  );
};

export default MaxScreen;
