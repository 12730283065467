import React from "react";

const Card = ({ card }) => {
  return (
    <div className="card-cover">
      <img className="card-image" alt={card.title} src={card.image} />
      <div className="card-box">
        <p className="card-title">{card.title}</p>
        <p className="card-para">{card.paragraph}</p>
        <a href={card.link} className="card-button">
          <p>Explore</p>
        </a>
      </div>
    </div>
  );
};

export default Card;
