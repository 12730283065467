import oceanTemp from "../assets/ocean-temp.png";
import three from "../assets/three.png";
import mentorly from "../assets/mentorly.png";

export const CARDS = [
  {
    image: oceanTemp,
    title: "Ocean Project",
    paragraph:
      "The web App introduces the idea of having a creative motion background.",
    link: "https://www.ocean-template.com/",
  },
  {
    image: three,
    title: "Three Gym Group Project",
    paragraph:
      "The best practice to improve your skills is by inhancing an existing website which gives you various oppertunities to mend broken UI/UX rules",
    link: "https://www.figma.com/proto/5udcfGDtkiW7MnhWyMVZAA/Three-Group-Gym?page-id=0%3A1&node-id=45-436&viewport=345%2C-653%2C0.26&scaling=scale-down&starting-point-node-id=45%3A436",
  },
  {
    image: mentorly,
    title: "Mentorly",
    paragraph:
      "Upon learning flutter I was given a chance to create a app version of warehouse which is the learnig platform of CODED.",
    link: "https://www.figma.com/file/SyAs8yItTK3D1EAEu7LhHK/Mentorly-App-Design?node-id=3%3A5&t=Z7vpVHbDZAARnMrw-1",
  },
];
