import React from "react";
// Style
import "../Styles/AboutMe.css";

const AboutMe = () => {
  return (
    <div className="about-me-container home-screen">
      <div className="about-me-text-container">
        <p className="about-me-title">About me</p>
        <p className="about-me-paragraph">
          Hey there! My name is <span className="taiba-sparkle">Taiba</span>{" "}
          AlThunayan I am a creative Kuwaiti software developer and a UI/UX
          designer. I graduated from Gulf University for Science and Technology
          as a computer scientist, later joined the Full stack development
          bootcamp at CODED and I am a certified UX designer. Welcome to check
          out my designs just scroll down to explore.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
