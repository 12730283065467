// Components
import AboutMe from "./Components/AboutMe";
import CardSection from "./Components/CardSection";
import Footer from "./Components/Footer";
import HomePage from "./Components/HomePage";
import MaxScreen from "./Components/MaxScreen";
import Navbar from "./Components/Navbar";

// Style
import "./Styles/App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <MaxScreen />
        <HomePage />
        <AboutMe />
        <CardSection />
        <Footer />
      </header>
    </div>
  );
}

export default App;
